import React from "react";
import { AppBar, Toolbar, IconButton, Badge } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = ({ cartQuantity, toggleCartDrawer }) => {
    const navigate = useNavigate();  // Menginisialisasi hook useNavigate
    const location = useLocation();  // Mendapatkan URL saat ini

    return(
        <AppBar position="sticky">
            <Toolbar>
                {location.pathname !== "/" && (
                    <IconButton color="inherit" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                <img src="https://via.placeholder.com/100" alt="Logo" style={{ maxHeight: '50px', marginRight: 'auto' }} />
                <IconButton color="inherit" onClick={() => navigate("/cart")}>
                    <Badge badgeContent={cartQuantity} color="secondary">
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
                {/* <IconButton color="inherit" onClick={() => toggleCartDrawer(true)}>
                    <Badge badgeContent={cartQuantity} color="secondary">
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton> */}
            </Toolbar>
        </AppBar>
    )
}

export default Header;