import React, { useState } from 'react';

import {Grid, Snackbar } from '@mui/material';
import ProductCard from './ProductCard';

const products = [
  { id: 1, name: 'Chicken Steak Mushroom', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 2, name: 'Chicken Steak BBQ', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 3, name: 'Chicken Popcorn', price: 20000, image: 'https://via.placeholder.com/150' },
  { id: 4, name: 'Ayam Suwir Kemangi', price: 20000, image: 'https://via.placeholder.com/150' },
  { id: 5, name: 'Ayam Saus Mentega', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 6, name: 'Ongkir Tambahan', price: 8000, image: 'https://via.placeholder.com/150' },
  // Tambahkan produk lainnya di sini...
];

const ProductList = ({  products, onAddToCart, cartItems, onRemoveFromCart, onTrashFromCart }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleAddToCart = (product) => {
        setOpenSnackbar(true);
        onAddToCart(product);
    };
    
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Grid container spacing={2}>
                {products.map((product) => (
                    <Grid item xs={6} sm={6} key={product.id}>
                        <ProductCard 
                            product={product} 
                            onAddToCart={onAddToCart}
                            onRemoveFromCart={onRemoveFromCart}
                            onTrashFromCart={onTrashFromCart}
                            cartItems={cartItems}
                        />
                    </Grid>
                ))}
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={800} // Snackbar akan menutup otomatis setelah 3 detik
                onClose={handleCloseSnackbar}
                message="Berhasil ditambahkan ke keranjang"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    );
};

export default ProductList;