import React, {useState, useEffect} from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Button, ButtonGroup, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ product, onAddToCart, cartItems, onRemoveFromCart, onTrashFromCart }) => {
    const [quantity, setQuantity] = useState(0);
    const [isAdded, setIsAdded] = useState(false);

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/product/${product.id}`);
    };

    useEffect(() => {
        const itemInCart = cartItems.find(item => item.id === product.id);
        if (itemInCart) {
            setQuantity(itemInCart.quantity);
            setIsAdded(true);
        } else {
            setQuantity(0);
            setIsAdded(false);
        }
    }, [cartItems, product.id]);

    const handleAddToCart = () => {
        setIsAdded(true);
        setQuantity(1);
        onAddToCart(product);
    };

    const handleIncrease = () => {
        setQuantity(quantity + 1);
        onAddToCart(product);
    };

    const handleDecrease = () => {
        if(quantity > 1){
            setQuantity(quantity - 1);
            onRemoveFromCart(product.id);
        }else{
            setIsAdded(false);
            setQuantity(0);
            onTrashFromCart(product.id);
        }
    }

    return (
        <Card onClick={handleClick}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt={product.name}
                    height="140"
                    image={product.image}
                    title={product.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2">
                        {product.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Rp {product.price.toLocaleString('id-ID')}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {isAdded ? (
                <ButtonGroup fullWidth variant='outlined' color='primary'>
                    <IconButton onClick={handleDecrease}>
                        <RemoveIcon/>
                    </IconButton>
                    <Typography variant='body1' style={{ margin: 'auto 0'}}>
                        {quantity}
                    </Typography>
                    <IconButton onClick={handleIncrease}>
                        <AddIcon/>
                    </IconButton>
                </ButtonGroup>
            ) : (
                <Button variant="outlined" color="primary" onClick={handleAddToCart}>
                    Tambahkan ke Keranjang
                </Button>
            )}
            
        </Card>
    );
};

export default ProductCard;