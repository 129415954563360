import React from "react";
import { Button, Typography, Box, IconButton, CardMedia, ListItem, ListItemText, List, CardActionArea, Card, CardContent, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import StoreIcon from '@mui/icons-material/Store';

const Cart = () => {

    return(
        <>
            <Box display="flex" marginLeft={1} marginTop={1} >
                <IconButton color="primary">
                    <StoreIcon />
                </IconButton>
                <Typography sx={{ fontSize: 15 }} marginTop={1} >
                    Ask Food Catering
                </Typography>
            </Box>
            <List>
                <Card variant="outlined">
                    <Grid container>
                        <Grid>
                            <Box marginTop={1} marginLeft={2} marginRight={1.5}>
                                <CardMedia
                                    component="img"
                                    alt="tes"
                                    sx={{ width: 75 }}
                                    image="https://via.placeholder.com/75" 
                                />
                            </Box>
                        </Grid>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography component="div" sx={{ marginTop: 1.5, fontSize: 15 }}>
                                Chicken Steak Sambal
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                color="primary"
                                sx={{ fontSize: 12 }}
                            >
                                Rp 10.000 x 1
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color:'text.secondary', fontSize: 12 }}
                            >
                                Subtotal Rp 10.000
                            </Typography>
                            <Box display="flex" justifyContent="end">
                                <Box display="flex" alignItems="center">
                                    <IconButton color="primary">
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton>
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography variant="body1">1</Typography>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                                
                        </Box>
                    </Grid>
                </Card>
                
                <Card variant="outlined">
                    <Grid container>
                        <Grid>
                            <Box marginTop={1} marginLeft={2} marginRight={1.5}>
                                <CardMedia
                                    component="img"
                                    alt="tes"
                                    sx={{ width: 75 }}
                                    image="https://via.placeholder.com/75" 
                                />
                            </Box>
                        </Grid>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography component="div" sx={{ marginTop: 1.5, fontSize: 15 }}>
                                Chicken Steak Sambal
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                color="primary"
                                sx={{ fontSize: 12 }}
                            >
                                Rp 10.000 x 1
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color:'text.secondary', fontSize: 12 }}
                            >
                                Subtotal Rp 10.000
                            </Typography>
                            <Box display="flex" justifyContent="end">
                                <Box display="flex" alignItems="center">
                                    <IconButton color="primary">
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton>
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography variant="body1">1</Typography>
                                    <IconButton>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                                
                        </Box>
                    </Grid>
                </Card>
            </List>
        </>
    );
};

export default Cart;