import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Box, IconButton, CardMedia } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const ProductDetail = ({ products, onAddToCart, onRemoveFromCart }) => {
    const { productId } = useParams();
    const product = products.find((item) => item.id === Number(productId));
  
    const [quantity, setQuantity] = React.useState(1);
  
    const handleIncrease = () => setQuantity(quantity + 1);
    const handleDecrease = () => quantity > 1 && setQuantity(quantity - 1);
  
    return (
      <Box padding={2}>
        <CardMedia
          component="img"
          alt={product.name}
          height="200"
          image={product.image}
          title={product.name}
        />
        <Typography variant="h5" color="primary" mt={2}>
          Rp {product.price.toLocaleString('id-ID')}
        </Typography>
        <Typography variant="h6">{product.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {product.vendor}
        </Typography>
        <Box mt={3}>
          <Typography variant="subtitle1">Deskripsi Product</Typography>
          <Typography variant="body1">{product.description}</Typography>
        </Box>
        
        <Box display="flex" alignItems="center" mt={2}>
          <Typography variant="body1" mr={2}>
            Jumlah Produk
          </Typography>
          <IconButton onClick={handleDecrease}>
            <RemoveIcon />
          </IconButton>
          <Typography variant="body1">{quantity}</Typography>
          <IconButton onClick={handleIncrease}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => onAddToCart({ ...product, quantity })}
          >
            + Tambah Keranjang
          </Button>
        </Box>
      </Box>
    );
  };
  
  export default ProductDetail;