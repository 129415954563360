import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import ProductList from './components/ProductList';
import ProductDetail from './pages/ProductDetail';
import Cart from './pages/Cart';
import { Container, Box, Drawer } from '@mui/material';

const products = [
  { id: 1, name: 'Chicken Steak Mushroom', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 2, name: 'Chicken Steak BBQ', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 3, name: 'Chicken Popcorn', price: 20000, image: 'https://via.placeholder.com/150' },
  { id: 4, name: 'Ayam Suwir Kemangi', price: 20000, image: 'https://via.placeholder.com/150' },
  { id: 5, name: 'Ayam Saus Mentega', price: 23000, image: 'https://via.placeholder.com/150' },
  { id: 6, name: 'Ongkir Tambahan', price: 8000, image: 'https://via.placeholder.com/150' },
];

const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setCartOpen] = useState(false);

  const handleAddToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevItems.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });
  };

  const handleRemoveFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems
      .map((item) => {
        if (item.id === productId && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
      .filter((item) => item.quantity > 0)
    );
  };

  const handleAddFromCart = (productId) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleTrashFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== productId));
  };

  const toggleCartDrawer = (open) => {
    setCartOpen(open);
  };

  return (
    <Router>
      <Box marginBottom={4}>
        <Header 
          cartQuantity={cartItems.reduce((total, item) => total + item.quantity, 0)} 
          toggleCartDrawer={toggleCartDrawer} 
        />
        <Routes>
        <Route 
            path="/cart" 
            element={<Cart/>} 
          />
        </Routes>
      </Box>
      <Box marginBottom={8}>
        <Container margin="normal">
          <Routes>
            <Route 
              path="/" 
              element={<ProductList 
                products={products}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
                onTrashFromCart={handleTrashFromCart}
                cartItems={cartItems} 
              />} 
            />
            <Route 
              path="/product/:productId" 
              element={<ProductDetail
                products={products}
                onAddToCart={handleAddToCart}
              />} 
            />
          </Routes>
        </Container>
      </Box>
      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={() => toggleCartDrawer(false)}
      >
        <Cart 
          cartItems={cartItems} 
          onRemoveFromCart={handleRemoveFromCart}  
          onAddFromCart={handleAddFromCart} 
          onTrashFromCart={handleTrashFromCart} 
        />
      </Drawer>
      <footer style={{ 
        width: '100%', 
        textAlign: 'center', 
        padding: '10px 0', 
        position: 'fixed',  // Membuat footer tetap di bagian bawah
        bottom: 0,  // Posisi di bagian bawah
        backgroundColor: '#f8f9fa' // Warna latar belakang yang netral
      }}>
        Powered By Andalan Solusi Kerja
      </footer>
    </Router>
  );
};

export default App;
